import * as React from "react"
import Layout from '../components/layout'
import '../components/styles.css'


import {
    PageContent,
    PageStyle,
} from '../components/style'

const IndexPage = () => {
  return (
    <PageStyle>
      <Layout pageTitle="superdevx">
      </Layout>
        <PageContent>
            I intend to use this site for personal things, but that may change; it is, in any case, under construction right now.
        </PageContent>
    </PageStyle>
  )
}

export default IndexPage
